<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_dsp',
      headers: [
        [{
          label: 'Jenis Sarana',
          dataKey: 'jenis_sarana',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah Unit',
          dataKey: 'jumlah_unit',
          order: 2,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Kualitas',
          dataKey: 'kualitas',
          order: 3,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
          render(data) {
            return data == 1 ? '✓' : ''
          }
        }, {
          label: 'Kondisi',
          attributes: { colspan: 2 },
        }, {
          label: 'Unit Pengelola (PS, UPPS, PT)',
          dataKey: 'unit_pengelola_ps_upps_pt',
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
          order: 6,
        }],
        [{
          label: 'Terawat',
          dataKey: 'terawat',
          order: 4,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Tidak Terawat',
          dataKey: 'tidak_terawat',
          order: 5,
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Sarana',
          name: 'jenis_sarana',
          dataKey: 'jenis_sarana',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Jumlah Unit',
          name: 'jumlah_unit',
          dataKey: 'jumlah_unit',
          type: 'number',
          
        }, {
          label: 'Kualitas',
          name: 'kualitas',
          dataKey(data) {
            return data.kualitas == 1 ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
        }, {
          label: 'Terawat',
          name: 'terawat',
          dataKey: 'terawat',
          type: 'number',
          col: 6,
          defaultValue: 0,
        }, {
          label: 'Tidak terawat',
          name: 'tidak_terawat',
          dataKey: 'tidak_terawat',
          type: 'number',
          col: 6,
          defaultValue: 0,
        }, {
          label: 'Unit Pengelola (PS, UPPS, PT)',
          name: 'unit_pengelola_ps_upps_pt',
          dataKey: 'unit_pengelola_ps_upps_pt',
          type: 'select',
          options: ['PS', 'UPPS', 'PT'],
          
          defaultValue: 'PS',
        }],
        customPayload(data) {
          return {
            ...data,
            kualitas: data.kualitas == '✓' ? 1 : 0,
          }
        },
      },
    }
  },
}
</script>
